<template>
  <div class="app-container">
    <!--    顶部按钮-->
    <el-row
      style="padding-bottom: 15px"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <el-col :span="3">
        <b>律协举办的培训</b>
      </el-col>
      <el-col :span="2"  :push="3"> 培训名称: </el-col>
      <el-col :span="4" >
        <el-input
          placeholder="请输入"
          v-model="searchTitle"
          clearable
          size="small"
        >
        </el-input>
      </el-col>
      <el-col :span="6" :pull="2">
        <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">搜索</el-button>
        <el-button  size="small"  @click="clearInput">重置</el-button>
      </el-col>
    </el-row>
    <!--表格-->
    <el-table
      v-loading="loading"
      element-loading-text="加载中"
      stripe
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#eee', color: '#333' }"
    >

      <el-table-column prop="startTime" label="开始时间" align="center" width="140"></el-table-column>
      <el-table-column prop="title" label="培训主题" align="center"></el-table-column>
      <el-table-column prop="type" label="培训类型" align="center" ></el-table-column>
      <el-table-column prop="score" label="学时" align="center" width="80"></el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="250" >
        <template slot-scope="scope">

          <div>
            <el-button
              size="mini"
              icon="el-icon-more"
              @click="handleDetail(scope.row)">
              查看详情
            </el-button>

            <el-button
              type="info"
              size="mini"
              icon="el-icon-s-custom"
              @click="handleAttend(scope.row)">
              报名名单
            </el-button>

          </div>

          <div style="margin-top: 10px">
            <el-button
              v-show="scope.row.deleteTime == null && new Date() < new Date(scope.row.endTime)"
              type="warning"
              size="mini"
              icon="el-icon-download"
              @click="handleDonwload(scope.$index, scope.row)">
              下载二维码
            </el-button>
            <el-button
              v-show="scope.row.deleteTime == null && new Date() < new Date(scope.row.endTime)"
              size="mini"
              type="danger"
              icon="el-icon-circle-close"
              @click="handleCancel(scope.$index, scope.row)"
            >
              取消会议
            </el-button>

            <el-button v-show="scope.row.deleteTime != null"
                       size="mini"
                       type="danger"
                       icon="el-icon-circle-close"
                       :disabled="true"

                       @click="handleCancel(scope.$index, scope.row)"
            >
              已取消
            </el-button>

            <el-button v-show="scope.row.deleteTime == null && new Date() > new Date(scope.row.endTime)"
                       size="mini"
                       type="success"
                       icon="el-icon-s-custom"
                       @click="handleRegister(scope.row)"
            >
              签到名单
            </el-button>

            <el-button v-show="scope.row.deleteTime == null && new Date() > new Date(scope.row.endTime)"
                       size="mini"
                       type="danger"
                       icon="el-icon-circle-check"
                       :disabled="true"

                       @click="handleCancel(scope.$index, scope.row)"
            >
              已结束
            </el-button>

          </div>
        </template>
      </el-table-column>



    </el-table>
    <!--      分页插件-->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      layout="total, prev, pager, next"
      :page-size="10"
      :total="total"
      style="margin-top: 20px; text-align: right">
    </el-pagination>


    <el-dialog
      :fullscreen="true"
      title="培训详情"
      :visible.sync="detailDialogVisible"
      width="60%"
      class="detailDialog"
    >
      <div>
<!--        <h1>{{currentData.mapAddress}}</h1>-->
        <el-row>
          <el-col :span="12">
            <h3>培训ID</h3>
            <span>{{currentData.id}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训主题</h3>
            <span>{{currentData.title}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="24">
            <h3>培训介绍</h3>
            <span>{{currentData.content}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>主讲嘉宾</h3>
            <span>{{currentData.host}}</span>
          </el-col>
          <el-col :span="12">
            <h3>最多可报名人数</h3>
            <span>{{currentData.enrollment}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>主办方</h3>
            <span>{{currentData.dname}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训群体</h3>
            <span>{{currentData.consumer}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>培训时间</h3>
            <span>{{currentData.startTime}} - </span><span>{{currentData.endTime}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训地点</h3>
            <span>{{currentData.addressTemp}}</span>

          </el-col>
        </el-row>

        <el-divider></el-divider>

        <h3>培训要求</h3>
        <span>{{currentData.memo}}</span>

      </div>

<!--      <span slot="footer" class="dialog-footer">-->
<!--     <el-button @click="detailDialogVisible = false">关闭</el-button>-->
<!--      </span>-->
    </el-dialog>
<!-- 报名名单-->
    <el-dialog
      title="报名名单"
      align="center"
      :visible.sync="attendDialogVisible"
      width="70%"
      class="attendDialog">
      <h3>已报{{attendPeople.length}}人</h3>
      <el-table
        v-loading="attend_loading"
        element-loading-text="加载中"
        :data="attendPeople"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          width="50">
        </el-table-column>


        <el-table-column
          align="center"
          prop="name"
          label="律师姓名"
          width="200">
        </el-table-column>

        <el-table-column
          align="center"
          prop="phone"
          label="联系方式"
          width="200">
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          label="身份"
          width="150">
        </el-table-column>
        <el-table-column
          prop="department"
          label="所属律所/单位"

          align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
<!--成功签到名单-->
    <el-dialog
      title="签到名单"
      align="center"
      :visible.sync="success_registerDialogVisible"
      width="70%"
      class="attendDialog">
      <h3>共{{success_register_lawyers.length}}人完成签到</h3>
      <el-table
        v-loading="success_register_loading"
        element-loading-text="加载中"
        :data="success_register_lawyers"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          width="50">
        </el-table-column>


        <el-table-column
          align="center"
          prop="name"
          label="律师姓名"
          width="200">
        </el-table-column>

        <el-table-column
          align="center"
          prop="phone"
          label="联系方式"
          width="200">
        </el-table-column>
<!--        <el-table-column-->
<!--          align="center"-->
<!--          prop="status"-->
<!--          label="身份"-->
<!--          width="150">-->
<!--        </el-table-column>-->
        <el-table-column
          prop="dname"
          label="所属律所"

          align="center">
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="培训二维码"
      :visible.sync="codeDialogVisible"
      width="50%"
      class="codeDialog"
    >
      <div>
        <el-row>
          <el-col :span="12">
            <h3>签到二维码</h3>
            <div class="image">
              <el-image
                style="width: 300px; height: 300px"
                :src="signCode">
              </el-image>
            </div>
          </el-col>
          <el-col :span="12">
            <h3>签退二维码</h3>
            <div class="image">
              <el-image
                style="width: 300px; height: 300px"
                :src="outCode">
              </el-image>
            </div>
          </el-col>
        </el-row>
        </div>
      </el-dialog>
  </div>


</template>

<script>
  import { request } from '@/network/network'
  import { register_type_dict,train_status_dict, train_consumer_dict,train_type_dict,train_status_options, train_consumer_options } from '@/utils/dict'


  export default {

    data() {
      return {

        loading: true,
        attend_loading: true,
        success_register_loading: false,
        success_register_lawyers: [],
        signCode:'',
        outCode:'',
        test:'',
        total: 0,
        currentPage: 0,
        searchTitle:"",
        searchMap:"",
        mapDialog: false,
        addTrainDialogVisible: false,
        attendDialogVisible: false,
        detailDialogVisible: false,
        codeDialogVisible: false,
        success_registerDialogVisible: false,

        currentData: {},
        url: [],


        tableData:[],
        attendPeople:[],
        //添加培训提交的表单
        applyForm: {
          title: '',
          desc: '',
          host: '',
          contacter:'',
          phone: '',
          consumer: '',
          enrollment: 50,
          score:'2',
          status:2,
          info:'',
          point:{ address: '', // 详细地址
            addrPoint: { // 详细地址经纬度
              lng: 0,
              lat: 0
            }},
          address: '',
          trainTime:[],
          startTime: '',
          endTime: '',
          memo: '',
          outCode:'',
          signCode:'',
          did:11,
        },

//添加培训表单验证规则
        rules: {
          title: [
            { required: true, message: '请输入培训主题', trigger: 'blur' },
            { min: 1, max: 50, message: '不能多于50字', trigger: 'blur' }
          ],
          consumer: [
            {required: true, message: '请选择培训群体', trigger: 'blur' },
          ],
          enrollment: [
            { required: true, message: '请选择最多可报名人数', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '培训地址不能为空', trigger: 'blur' },

          ],
          trainTime: [
            { required: true, message: '请选择培训时间', trigger: 'blur' }
          ],
        },
      };
    },
    methods: {
      //分页插件绑定事件
      handleCurrentChange(val) {
        this.loading = true
        this.currentPage = val
        this.getTrains(this.searchMap, val);
      },
      //搜索按钮
      handleSearch() {
        this.searchMap = this.searchTitle
        this.getTrains(this.searchMap);

      },
      //重置搜索框
      clearInput() {
        this.searchTitle = "";
        this.searchMap = "",
          this.getTrains()
      },
      //点击查看详情按钮
      handleDetail(row) {
        this.detailDialogVisible = true
        this.currentData = row
        let map = JSON.parse(row.mapAddress)
        this.currentData.addressTemp = map.district +' - '+ map.address+' - ' + map.name +' ('+this.currentData.address+')'
      },
      //点击查看参会人员按钮
      handleAttend(row) {
        this.attend_loading = true
        this.attendDialogVisible = true
        this.register(row.id)
      },
      //点击查看成功签到名单
      handleRegister(row){
        this.success_register_loading = true;
        this.success_registerDialogVisible = true
        this.success_register(row.id)

      },
      // 取消会议按钮
      handleCancel(index, row) {
        this.$confirm("是否要取消该会议?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.cancelTrain(row.id)
              .then((res) => {
                this.$message({
                  message: "取消成功！",
                  type: "success",
                  duration: 1000,
                });
                this.getTrains();

              })
              .catch((err) => {
                this.$message({
                  message: "发生错误，取消失败！",
                  type: "error",
                  duration: 1000,
                });
                this.getTrains();

              });
          })
          .catch(() => {
            row.purchase = false;
            this.$message({
              message: "操作取消！",
              type: "info",
              duration: 1000,
            });
          });

      },

      //下载按钮
      handleDonwload(index, row) {
        this.signCode = 'http://org.shuobocloud.cn' + row.signCode
        this.outCode = 'http://org.shuobocloud.cn' + row.outCode
        // this.signCode = 'http://49.234.55.215:8083/qrcode/' + row.photo+ ".jpg"
        console.log(this.signCode)
        this.codeDialogVisible = true
      },
      //添加培训按钮
      handleAddTrain() {
        this.addTrainDialogVisible = true
      },
      beforeSubmit(applyForm) {
        console.log(this.applyForm)
        this.$refs[applyForm].validate((valid) => {
          if (valid) {
            this.submitForm()
          } else {
            this.$message({
              message: '请按要求完善培训信息！',
              type: 'warning',
              duration: 1000,
            });
            return false;
          }
        });
      },
      //添加培训提交按钮
      submitForm() {
        this.$set(this.applyForm, 'startTime', this.applyForm.trainTime[0])
        this.$set(this.applyForm, 'endTime', this.applyForm.trainTime[1])


      },
      getTrains(title, page) {
        return request({
          url: '/train/trains',
          method: 'get',
          params: {
            title: title,
            page: page,
            status: 2,
            dtype: 1
          }
        }).then(res => {
          this.total = res.data.total
          let temp = res.data.list
          this.tableData = temp.map(item => {
            item.status = train_status_dict[item.status];
            item.consumer =  train_consumer_dict[item.consumer];
            // item.startTime = formatDate(item.startTime)
            // item.endTime = formatDate(item.endTime)
            // item.createTime = formatDate(item.createTime)
            item.type = train_type_dict[item.type]
            return item
          })
          this.loading = false
        })
      },

      cancelTrain(id) {
        return request({
          url: '/train/cancelTrain',
          method: 'get',
          params: {
            id: id
          }
        })
      },
      register(tid) {
        return request({
          url: '/train/register',
          method: 'get',
          params: {
            tid: tid
          }
        }).then(res => {
          let temp = res.data
          this.attendPeople = temp.map(item => {
            item.status =  register_type_dict[item.status];
            return item
          })
          this.attend_loading = false
        })
      },
      success_register(tid) {
        return request({
          url: '/train/success_register',
          method: 'get',
          params: {
            tid: tid
          }
        }).then(res => {
          this.success_register_lawyers = res.data
          this.success_register_loading = false

        })
      },
    },



    created() {
      this.getTrains();
    },
  }
</script>

<style scoped>
  .input-width {
    width: 80%;
  }

</style>

