<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="协会培训" name="first" >
      <MyTrain v-if="isFirst"></MyTrain>
    </el-tab-pane>
    <el-tab-pane label="律所培训" name="second">
      <OtherTrain v-if="isSecond"></OtherTrain>
    </el-tab-pane>
  </el-tabs>
</template>

<script>

  import MyTrain from '@/views/trainManageA/trainListChildrend/MyTrain'
  import OtherTrain from '@/views/trainManageA/trainListChildrend/OtherTrain'
  export default {
    components:{MyTrain,OtherTrain},
    data() {
      return {
        activeName: 'first',
        isFirst: true,
        isSecond: true
      };
    },
    methods: {
      handleClick(tab, event) {
        if(tab.name == "first") {
          this.isFirst = true;
          this.isSecond = false;
        }
        else if(tab.name == "second") {
          this.isFirst = false;
          this.isSecond = true;
        }
      }
    }


  }
</script>

<style scoped>

</style>
