<template>

  <div class="app-container">
    <!--    顶部按钮-->
    <el-row
      style="padding-bottom: 15px"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <el-col :span="3">
        <b>律所举办的培训</b>
      </el-col>
      <el-col :span="2"  :push="3"> 培训名称: </el-col>
      <el-col :span="4" >
        <el-input
          placeholder="请输入"
          v-model="searchTitle"
          clearable
          size="small"
        >
        </el-input>
      </el-col>
      <el-col :span="6" :pull="2">
        <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">搜索</el-button>
        <el-button type="warning" size="small"  @click="clearInput">重置</el-button>
      </el-col>
    </el-row>
    <!--表格-->
    <el-table
      v-loading="loading"
      element-loading-text="加载中"
      stripe
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#eee', color: '#333' }"
      :default-sort = "{prop: 'startTime', order: 'descending'}"
    >
      <el-table-column prop="startTime" label="开始时间" align="center" width="140px"></el-table-column>
      <el-table-column prop="dname" label="主办方" align="center" width="200"></el-table-column>
      <el-table-column prop="title" label="培训主题" align="center" ></el-table-column>
      <el-table-column prop="type" label="培训类型" align="center" width="140"></el-table-column>

      <!--      <el-table-column prop="desc" label="培训介绍" align="center"></el-table-column>-->
      <!--      <el-table-column prop="host" label="主持人" align="center"></el-table-column>-->
      <!--      <el-table-column prop="address" label="培训地点" align="center"></el-table-column>-->
      <!--      <el-table-column prop="memo" label="其他要求" align="center"></el-table-column>-->
      <!--      <el-table-column prop="consumer" label="培训对象" align="center" width="120"></el-table-column>-->

<!--      <el-table-column prop="contacter" label="联系人" align="center" width="100"></el-table-column>-->
<!--      <el-table-column prop="phone" label="联系电话" align="center"  width="150"></el-table-column>-->
<!--      <el-table-column prop="startTime" label="开始时间" align="center"width="150"></el-table-column>-->
      <!--      <el-table-column prop="end_time" label="结束时间" align="center"></el-table-column>-->
<!--      <el-table-column prop="score" label="学时" align="center" width="80"></el-table-column>-->
      <!--      <el-table-column prop="enrollment" label="最大人数" align="center"></el-table-column>-->
      <!--      <el-table-column prop="staus" label="审核状态" align="center"></el-table-column>-->
      <!--      <el-table-column prop="info" label="审核意见" align="center"></el-table-column>-->
      <!--      <el-table-column prop="sign_code" label="签到二维码" align="center"></el-table-column>-->
      <!--      <el-table-column prop="out_code" label="签退二维码" align="center"></el-table-column>-->

      <el-table-column label="操作" align="center" width="250">
        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon-more"
            @click="handleDetail(scope.row)">
            查看详情
          </el-button>

          <el-button v-show="scope.row.deleteTime == null"
            type="info"
            size="mini"
            icon="el-icon-s-custom"
            @click="handleAttend(scope.row)">
            报名名单
          </el-button>

          <div style="margin-top: 10px">
          <el-button v-show="scope.row.deleteTime == null && new Date() > new Date(scope.row.endTime)"
                     size="mini"
                     type="success"
                     icon="el-icon-s-custom"
                     @click="handleRegister(scope.row)"
          >
            签到名单
          </el-button>
          <el-button v-show="scope.row.deleteTime == null && new Date() > new Date(scope.row.endTime)"
                     size="mini"
                     type="danger"
                     icon="el-icon-circle-check
"
                     :disabled="true"

                     @click="handleCancel(scope.$index, scope.row)"
          >
            已结束
          </el-button>

          <el-button v-show="scope.row.deleteTime != null"
                     size="mini"
                     type="danger"
                     icon="el-icon-circle-close"
                     :disabled="true"

                     @click="handleCancel(scope.$index, scope.row)"
          >
            已取消
          </el-button>
            </div>
        </template>
      </el-table-column>
    </el-table>

    <!--      分页插件-->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      layout="total, prev, pager, next"
      :page-size="10"
      :total="total"
      style="margin-top: 20px; text-align: right">
    </el-pagination>

    <el-dialog
      :fullscreen="true"
      title="培训详情"
      :visible.sync="detailDialogVisible"
      width="60%"
    >
      <div>
        <el-row>
          <el-col :span="12">
            <h3>培训ID</h3>
            <span>{{currentData.id}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训主题</h3>
            <span>{{currentData.title}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="24">
            <h3>培训介绍</h3>
            <span>{{currentData.content}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>主讲嘉宾</h3>
            <span>{{currentData.host}}</span>
          </el-col>
          <el-col :span="12">
            <h3>最多可报名人数</h3>
            <span>{{currentData.enrollment}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>主办方</h3>
            <span>{{currentData.dname}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训群体</h3>
            <span>{{currentData.consumer}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>培训时间</h3>
            <span>{{currentData.startTime}} - </span><span>{{currentData.endTime}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训地点</h3>
            <span>{{currentData.addressTemp}}</span>

          </el-col>
        </el-row>

        <el-divider></el-divider>

        <h3>培训要求</h3>
        <span>{{currentData.memo}}</span>

      </div>

      <span slot="footer" class="dialog-footer">
<!--    <el-button @click="detailDialogVisible = false">关闭</el-button>-->
        <!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
  </span>
    </el-dialog>


    <el-dialog
      title="报名名单"
      align="center"
      :visible.sync="attendDialogVisible"
      width="70%"
      class="attendDialog">

<!--      {{attendPeople}}-->
      <h3>已报{{attendPeople.length}}人</h3>
      <el-table
        v-loading="attend_loading"
        element-loading-text="加载中"
        :data="attendPeople"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">


        <el-table-column
          align="center"
          prop="name"
          label="律师姓名"
          width="200">
        </el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="联系方式"
          width="200">
        </el-table-column>


        <el-table-column
          align="center"
          prop="status"
          label="身份"
          width="150">
        </el-table-column>

        <el-table-column
          prop="department"
          label="所属律所/单位"
          align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--成功签到名单-->
    <el-dialog
      title="签到名单"
      align="center"
      :visible.sync="success_registerDialogVisible"
      width="70%"
      class="attendDialog">
      <h3>共{{success_register_lawyers.length}}人完成签到</h3>
      <el-table
        v-loading="success_register_loading"
        element-loading-text="加载中"
        :data="success_register_lawyers"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          width="50">
        </el-table-column>


        <el-table-column
          align="center"
          prop="name"
          label="律师姓名"
          width="200">
        </el-table-column>

        <el-table-column
          align="center"
          prop="phone"
          label="联系方式"
          width="200">
        </el-table-column>
        <!--        <el-table-column-->
        <!--          align="center"-->
        <!--          prop="status"-->
        <!--          label="身份"-->
        <!--          width="150">-->
        <!--        </el-table-column>-->
        <el-table-column
          prop="dname"
          label="所属律所"

          align="center">
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>

</template>

<script>
  import {request} from '@/network/network'
  import { register_type_dict,train_status_dict, train_consumer_dict,train_type_dict,train_status_options, train_consumer_options } from '@/utils/dict'

  export default {

    data(){
      return{
        loading: true,
        attend_loading: true,
        success_register_loading: false,
        success_register_lawyers: [],
        total: 0,
        currentPage: 0,
        searchTitle:"",
        searchMap:"",
        attendDialogVisible: false,
        detailDialogVisible: false,
        editDialogVisible: false,
        cancelDialogVisible: false,
        addTrainDialogVisible: false,
        tableData:[],
        currentData: {},
        attendPeople:[],
        success_registerDialogVisible: false,
      }
    },

    methods:{
      //分页插件绑定事件
      handleCurrentChange(val) {
        this.loading = true
        this.currentPage = val
        this.getTrains(this.searchMap ,val);
      },
      //搜索按钮
      handleSearch(){
        this.searchMap = this.searchTitle
        this.getTrains(this.searchMap);

      },
      //重置搜索框
      clearInput() {
        this.searchTitle = "";
        this.searchMap = "",
          this.getTrains()
      },
      //点击查看详情按钮
      handleDetail(row){
        this.detailDialogVisible = true
        this.currentData = row
        let map = JSON.parse(row.mapAddress)
        this.currentData.addressTemp = map.district +' - '+ map.address+' - ' + map.name +' ('+this.currentData.address+')'

      },
      //点击查看参会人员按钮
      handleAttend(row){
        this.attend_loading = true
        this.attendDialogVisible=true
        this.register(row.id)
      },
      //点击查看成功签到名单
      handleRegister(row){
        this.success_register_loading = true;
        this.success_registerDialogVisible = true
        this.success_register(row.id)

      },

      getTrains(title, page){
        return request({
          url: '/train/trains',
          method:'get',
          params:{
            title:title,
            page: page,
            status: 2,
            dtype: 0
          }
        }).then(res => {
          this.total = res.data.total
          let temp = res.data.list
          this.tableData = temp.map(item => {
            item.status = train_status_dict[item.status];
            item.consumer =  train_consumer_dict[item.consumer];
            // item.startTime = formatDate(item.startTime)
            // item.endTime = formatDate(item.endTime)
            // item.createTime = formatDate(item.createTime)
            item.type = train_type_dict[item.type]
            return item
          })
          this.loading = false
        })
      },

      register(tid){
        return request({
          url: '/train/register',
          method: 'get',
          params: {
            tid: tid
          }
        }).then(res => {
          let temp = res.data
          this.attendPeople = temp.map(item => {
            item.status =  register_type_dict[item.status];

            return item
          })
          this.attend_loading = false
        })
      },
      success_register(tid) {
        return request({
          url: '/train/success_register',
          method: 'get',
          params: {
            tid: tid
          }
        }).then(res => {
          this.success_register_lawyers = res.data
          this.success_register_loading = false

        })
      },
    },
    created() {
      this.getTrains();

    }
  }
</script>

<style scoped>

</style>
